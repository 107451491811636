<template>
    <article class="elementor-post elementor-grid-item post-525 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized">
				<router-link to="/news" class="elementor-post__thumbnail__link" tabindex="-1" >
			<div class="elementor-post__thumbnail"><img loading="lazy" decoding="async" width="300" height="200" :src="news.photo" class="attachment-medium size-medium wp-image-515" alt="" /></div>
                </router-link>
				<div class="elementor-post__text">
				<h3 class="elementor-post__title">
			<router-link to="/news">
				{{ news.text }}			</router-link>
		</h3>
				<div class="elementor-post__meta-data">
					<span class="elementor-post-date">
			{{ news.created_at }}		</span>
				</div>

				</div>
				</article>
</template>

<script>
export default {
    name: "NewsComponent",
    props: {
        news: Object
    }
}
</script>