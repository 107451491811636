<template>
<main id="content" class="site-main post-59 page type-page status-publish hentry">
	<div class="elementor-page-338 elementor-338">
	<section class="elementor-section elementor-top-section elementor-element elementor-element-8c71424 elementor-section-full_width elementor-section-height-min-height elementor-section-height-default elementor-section-items-middle" data-id="8c71424" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
							<div class="elementor-background-overlay"></div>
							<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-729f07b" data-id="729f07b" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-1611c7d elementor-widget elementor-widget-theme-page-title elementor-page-title elementor-widget-heading" data-id="1611c7d" data-element_type="widget" data-widget_type="theme-page-title.default">
				<div class="elementor-widget-container">
					<h1 class="elementor-heading-title elementor-size-default">About Us</h1>		</div>
				</div>
					</div>
		</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-92dcf0f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="92dcf0f" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d20b375" data-id="d20b375" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-70c0986 elementor-widget elementor-widget-image" data-id="70c0986" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
													<img width="700" height="400"  class="attachment-large size-large wp-image-334" alt="" src="https://tradehouse-malaysia.com/wp-content/uploads/2024/04/1708432062110.jpeg" />													</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-255ee30" data-id="255ee30" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-f63fc54 elementor-widget elementor-widget-heading" data-id="f63fc54" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">About Us</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-1bd08f9 elementor-widget elementor-widget-text-editor" data-id="1bd08f9" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container" v-html="page.top">

				</div>
				</div>
					</div>
		</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-b30270b elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="b30270b" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-554673f" data-id="554673f" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-9eb7988 elementor-widget elementor-widget-image" data-id="9eb7988" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
													<img decoding="async" width="800" height="400" class="attachment-large size-large wp-image-346" alt="" src="https://tradehouse-malaysia.com/wp-content/uploads/2024/04/09.08.2020.png" />													</div>
				</div>
					</div>
		</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-9d04de8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9d04de8" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-82e6a92" data-id="82e6a92" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-2fe3d7e elementor-widget__width-initial elementor-widget elementor-widget-text-editor" data-id="2fe3d7e" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container" v-html="page.bottom"></div>
				</div>
					</div>
		</div>
					</div>
		</section>
	</div>
				<FeedbackFormComponent />
				<ContactsComponent />
				
</main>
</template>

<script>
import FeedbackFormComponent from '@/components/FeedbackFormComponent.vue';
import ContactsComponent from '@/components/ContactsComponent.vue';

export default {
    name: "AboutView",
	components: {
		FeedbackFormComponent,
		ContactsComponent
	},
	data() {
		return {
			page: "",
		}
	},
  mounted() {
    this.fetchContent();
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + `/api/get/tradehouse/${this.$langStore.lang}/about`);
        if (!response.ok) throw new Error('Ошибка запроса');
        const data = await response.json();
		this.page = data;
		
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    }
  }
}
</script>

<style scoped lang="scss">

.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}
.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}

</style>