<template>
    <section class="elementor-section elementor-top-section elementor-element elementor-element-4f55db95 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="4f55db95" data-element_type="section" id="news" data-settings="{&quot;animation&quot;:&quot;slideInUp&quot;}">
							<div class="elementor-background-overlay"></div>
							<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-322acac7" data-id="322acac7" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-46235692 elementor-widget elementor-widget-heading" data-id="46235692" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">NEWS</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-51a4c9de elementor-grid-4 elementor-grid-tablet-2 elementor-grid-mobile-1 elementor-posts--thumbnail-top elementor-widget elementor-widget-posts" data-id="51a4c9de" data-element_type="widget" data-settings="{&quot;classic_columns&quot;:&quot;4&quot;,&quot;classic_columns_tablet&quot;:&quot;2&quot;,&quot;classic_columns_mobile&quot;:&quot;1&quot;,&quot;classic_row_gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:35,&quot;sizes&quot;:[]},&quot;classic_row_gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;classic_row_gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}" data-widget_type="posts.classic">
				<div class="elementor-widget-container"><div class="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid elementor-has-item-ratio">
					<NewsComponent v-for="(item, index) in news" :news="item" :key="index" />
				</div>
		
				</div>
				</div>
					</div>
		</div>
					</div>
		</section>
  </template>
  
  <script>
  import NewsComponent from './NewsComponent.vue';
  export default {
	name: "NewsListComponent",
	components: {
		NewsComponent
	},
	data() {
		return {
			news: [],
		}
	},
  mounted() {
    this.fetchContent();
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + `/api/news/get`);
        if (!response.ok) throw new Error('Ошибка запроса');
        const data = await response.json();
		this.news = data;
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    }
  }
  }
  </script>
  
  <style scoped lang="scss">
  </style>