<template>
	<div class="elementor-page-316 elementor-316">
		<section class="elementor-section elementor-top-section elementor-element elementor-element-c3e494d elementor-section-full_width elementor-section-height-min-height elementor-section-height-default elementor-section-items-middle" data-id="c3e494d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
							<div class="elementor-background-overlay"></div>
							<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a7b1eb9" data-id="a7b1eb9" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-44d07ea elementor-widget elementor-widget-theme-page-title elementor-page-title elementor-widget-heading" data-id="44d07ea" data-element_type="widget" data-widget_type="theme-page-title.default">
				<div class="elementor-widget-container">
					<h1 class="elementor-heading-title elementor-size-default">Market Research and Analysis for Export Activities</h1>		</div>
				</div>
					</div>
		</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-83794f0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="83794f0" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ae5afc0" data-id="ae5afc0" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-87e2dd1 elementor-widget elementor-widget-image" data-id="87e2dd1" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
													<img decoding="async" width="800" height="534" src="../wp-content/uploads/2024/04/Exploring-the-Synergy-Between-Exporting-and-Importing-A-Strategic-Approach-1024x683.png" class="attachment-large size-large wp-image-324" alt="" />													</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-2baffc0" data-id="2baffc0" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-afbd064 elementor-widget elementor-widget-heading" data-id="afbd064" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">What we do</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-d33ad57 elementor-widget elementor-widget-text-editor" data-id="d33ad57" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container" v-html="page.text">
								</div>
				</div>
					</div>
		</div>
					</div>
		</section>
	</div>
		<FeedbackFormComponent />
		<ContactsComponent />
</template>

<script>
import FeedbackFormComponent from '@/components/FeedbackFormComponent.vue';
import ContactsComponent from '@/components/ContactsComponent.vue';

export default {
    name: "MarketView",
	components: {
		FeedbackFormComponent,
		ContactsComponent
	},
	data() {
		return {
			page: "",
		}
	},
  mounted() {
    this.fetchContent();
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + `/api/get/tradehouse/${this.$langStore.lang}/market`);
        if (!response.ok) throw new Error('Ошибка запроса');
        const data = await response.json();
		this.page = data;
		
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    }
  }
}
</script>

<style scoped lang="scss">

.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}
.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}


</style>