<template>
					<section class="elementor-section elementor-top-section elementor-element elementor-element-6786205 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6786205" data-element_type="section" id="team">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4e82e93" data-id="4e82e93" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-ed15cdc elementor-invisible elementor-widget elementor-widget-heading" data-id="ed15cdc" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;slideInDown&quot;}" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">OUR <span style="color:#0563c7">PARTNERS</span></h2>		</div>
				</div>
				<div class="elementor-element elementor-element-d07ed37 elementor-invisible elementor-widget elementor-widget-image-gallery" data-id="d07ed37" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;slideInLeft&quot;}" data-widget_type="image-gallery.default">
				<div class="elementor-widget-container">
<div class="elementor-image-gallery">
			<div id='gallery-1' class='gallery galleryid-59 gallery-columns-4 gallery-size-thumbnail'><figure class='gallery-item' v-for="(item, index) in imageObject" :key="index">
			<div class='gallery-icon landscape'>
				<img loading="lazy" decoding="async" width="150" height="150" :src="item.src" class="attachment-thumbnail size-thumbnail" alt="" />
			</div></figure>
		</div>
		</div>
				</div>
				</div>
					</div>
		</div>
					</div>
		</section>
  </template>
  
  <script>
  import { ref } from 'vue';
  export default {
      name: "PartnersComponent",
      setup() {
		const files = ref([]);
		const imageObject = ref([]);

		const loadImageNames = () => {
        fetch(process.env.VUE_APP_API_URL + `/api/files/list/tradepartners`)
            .then(response => response.json())
            .then(data => {
                files.value = data.files;
				files.value.forEach(element => {
                    imageObject.value.push({
                        id: element.split('.')[0],
                        src: process.env.VUE_APP_API_URL + "/api/files/tradepartners/" + element,
                    })
                });
            })
            .catch(error => console.error('Ошибка загрузки контента:', error));
        };

        loadImageNames();

		return {
			files,
			imageObject
		}
      }
  }
  </script>
  
  <style scoped lang="scss">
  
  </style>